import { ReactNode } from "react";

interface Props {
  imgUrl: string;
  imgAlt: string;
  title: ReactNode;
  content: ReactNode;
}

const DesignGuideCard = ({ imgUrl, imgAlt, title, content }: Props) => {
  return (
    <div className="mb-6 last:mb-0 md:mb-0">
      <div className="relative pt-2 px-2 rounded-2xl mb-6 flex items-center flex-col">
        <img src={imgUrl} alt={imgAlt} />
      </div>
      <div>
        <div className="mb text-center text-[#1F2024] text-xl font-semibold mb-2 md:mb-[14px] whitespace-pre-wrap">
          {title}
        </div>
        <div className="text-center text-[#3A3B40] text-[15px] font-normal whitespace-pre-wrap">{content}</div>
      </div>
    </div>
  );
};
export default DesignGuideCard;
