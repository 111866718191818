import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import { memo } from "react";
import Head from "next/head";
import "swiper/css";
import "swiper/css/pagination";
import { ChevronRightLineRegular, ContainerButton, Divider, PhoneFill, TextBody } from "@greenlabs/formula-components";

import LandingStyles from "./landing.module.css";
import { PageProps } from "@/pages/_app";
import Card from "@feature/landing/card";
import { withAuth } from "@feature/auth";
import { landingCardData, landingDesignGuideData, landingSwiperData } from "@constants/landing-data";
import DesignCard from "@feature/landing/design-card";
import DesignGuideCard from "@feature/landing/design-guide-card";
import CardStat from "@feature/landing/card-stat";
import BannerContent from "@feature/landing/banner-content";

const ChevronRightIcon = () => {
  return <ChevronRightLineRegular size="xl" color="white" />;
};

const PhoneFillIcon = () => {
  return <PhoneFill size="xl" color="primary-contents" />;
};

function Landing(_: PageProps) {
  const handleSignUpClick = () => {
    window.open(`${process.env.NEXT_PUBLIC_MEMBER_URL}/partner`);
  };

  const handleCounsel = () => {
    window.open(process.env.NEXT_PUBLIC_INBOUND_URL);
  };

  const handleCall = () => {
    location.href = `tel:02-6953-4379`;
  };

  return (
    <>
      <Head>
        <title>브이하우스</title>
      </Head>
      {/** 렌딩 스와이퍼 */}
      <section>
        <Swiper
          modules={[Pagination, Autoplay]}
          autoplay={true}
          pagination={{
            enabled: true,
            bulletClass: `swiper-pagination-bullet ${LandingStyles["landing-slide-pagination-bullet"]}`,
            bulletActiveClass: `swiper-pagination-bullet-active ${LandingStyles["landing-slide-pagination-bullet-active"]}`,
          }}
        >
          {landingSwiperData.map((v, i) => (
            <SwiperSlide
              key={`slide-${i}`}
              className={`${LandingStyles[`landing-slide-${i + 1}`]} ${LandingStyles["landing-slide"]}`}
            >
              <div>
                <h5 className={LandingStyles["landing-slide-title"]}>{v.title}</h5>
                <h2 className={`whitespace-pre-wrap ${LandingStyles["landing-slide-content"]}`}>{v.content}</h2>
                <ContainerButton
                  onClick={handleSignUpClick}
                  className={LandingStyles["landing-slide-btn"]}
                  size="lg"
                  color="primary"
                  text={v.button}
                  rightIcon={ChevronRightIcon}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>

      {/** 렌딩 컨텐츠 */}
      <section className="max-w-[1440px] mx-4 lg:mx-[7%] xl:m-auto">
        <h2 className={LandingStyles["content-head-text"]}>
          브이하우스 시공 중개 서비스로 <br />더 많은 수익을 만들어보세요
        </h2>
        <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
          {landingCardData.map((v, i) => (
            <Card key={`card-${i}`} img={v.imgUrl} imgAlt={v.imgAlt} title={v.title} description={v.description}></Card>
          ))}
        </div>

        {/** 랜딩 배너*/}

        <div className="mt-20 p-8 md:p-4 bg-[#F7F8FA] rounded-2xl border border-zinc-200 flex flex-col md:flex-row text-center md:text-left">
          <BannerContent
            className="flex-1"
            handleClick={handleSignUpClick}
            title={
              <>
                <span className="text-[#0BB25F]">농자재 유통사 & 하우스 시공 업체</span> <br className="md:hidden" />
                담당자 이신가요?
              </>
            }
            content={
              <>
                지금 브이하우스 무료 가입하고, <br className="md:hidden" />
                온라인으로 바로 이용해 보세요
              </>
            }
            buttonText="브이하우스 무료 가입하기"
          />

          <Divider variant="small" className="md:h-32 md:w-[1px] bg-[#DCDFE3]" />

          <BannerContent
            className="flex-1 md:mt-0 mt-8"
            handleClick={handleCounsel}
            title={
              <>
                <span className="text-[#0BB25F]">견적 상담을 받고 싶은</span> <br className="md:hidden" />
                고객님이신가요?
              </>
            }
            content={
              <>
                하우스 견적을 직접 해보고 <br className="md:hidden" />
                전문가 무료 상담도 받아보세요
              </>
            }
            buttonText="하우스 견적 상담 받기"
          />
        </div>

        <div className="mt-15 md:mt-32">
          <h1 className="md:leading-12 mb-15 text-center text-black text-2xl md:text-4xl font-bold">
            그리고
            <br className="md:hidden" /> 하우스 전문 설계 서비스를 통해
            <br />더 쉽고 편하게 업무하세요
          </h1>

          <DesignCard
            variant={"single"}
            subTitle={<>설계 자동화 시스템</>}
            title={
              <>
                복잡했던 설계, 견적을
                <br />
                자동화 시스템으로 한 번에
              </>
            }
            content={
              <>
                하우스의 다양한 구조과 설비를 단 1-2분 만에
                <br />
                자동으로 견적할 수 있습니다.
                <br />
                창고/농막, 단동, 연동 등 수많은 설계 템플릿을
                <br />
                브이하우스에서 제공합니다.
              </>
            }
            imgUrl={"/images/landing/design-1.png"}
            imgAlt={"설계 자동화 시스템"}
            className="bg-[#5C6875]"
          />

          <div className="gap-x-8 flex flex-col md:flex-row">
            <DesignCard
              variant={"multiple"}
              subTitle={<>손쉬운 업무 관리</>}
              title={<>자재 내역, 도면, 시방서 관리까지</>}
              content={
                <>
                  설계 완료 후 견적서, 자재 내역, 도면, 시방서를
                  <br /> A4용지에 출력할 수 있습니다.
                </>
              }
              imgUrl={"/images/landing/design-2.png"}
              imgAlt={"손쉬운 업무 관리"}
              className="bg-[#5ABF86] flex-1"
            />

            <DesignCard
              variant={"multiple"}
              subTitle={<>업무 자료 제공</>}
              title={<>모든 시설 자재 DB와 단가 제공</>}
              content={
                <>
                  커튼, 천창, 관수, 양액 등 모든 자재를 규격화하여 DB로 제공합니다.
                  <br className="hidden md:block" /> 모든 상품에 대해 시장 평균 단가를 지속적으로 업데이트합니다.
                </>
              }
              imgUrl={"/images/landing/design-3.png"}
              imgAlt={"업무 자료 제공"}
              className="bg-[#56C2B7] flex-1"
            />
          </div>
        </div>

        <div className="mt-15 md:mt-28 hidden lg:block">
          <h1 className="mb-15 text-center text-black text-2xl md:text-4xl font-bold leading-10">
            전문 설계 서비스는
            <br className="md:hidden" /> 어떻게 운영하나요?
          </h1>
          <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
            {landingDesignGuideData.map((v, i) => (
              <DesignGuideCard
                key={`design-guide-${i}`}
                imgUrl={v.imgUrl}
                imgAlt={v.imgAlt}
                title={v.title}
                content={v.content}
              />
            ))}
          </div>
        </div>
      </section>

      <section className="flex bg-[#4A5562] my-20">
        <div className="max-w-[1440px] w-full mx-4 lg:mx-[7%] xl:mx-auto my-10 md:my-14">
          <h1 className="md:leading-12 text-2xl text-center text-white md:text-4xl font-bold mb-8 md:mb-14 ">
            많은 분들로부터 검증된
            <br className="md:hidden" /> 브이하우스 입니다.
          </h1>

          <div className="gap-x-7 flex w-full justify-center gap-y-6 md:gap-y-0  flex-col md:flex-row">
            <CardStat
              title={
                <>
                  수많은 고객들이 <br />
                  시공 상담을 기다립니다
                </>
              }
              content={[
                {
                  title: "누적 중개 건수",
                  value: 131,
                  valueType: "count",
                },
                {
                  title: "누적 중개 금액",
                  value: 7_714_558_057,
                  valueType: "won",
                },
              ]}
              className="bg-[#31CC7F] flex-1"
            />

            <CardStat
              title={
                <>
                  수많은 업체들이 <br />
                  전문 설계를 사용 중입니다.
                </>
              }
              content={[
                {
                  title: "누적 견적 건수",
                  value: 3567,
                  valueType: "count",
                },
                {
                  title: "누적 견적 금액",
                  value: 122_513_552_830,
                  valueType: "won",
                },
              ]}
              className="bg-[#0DC5BC] flex-1"
            />
          </div>
        </div>
      </section>

      <div className="my-15 flex md:my-28  bg-[#F0F2F5]   rounded-2xl max-w-[1440px] mx-4 lg:mx-[7%] xl:mx-auto">
        <div className="flex flex-col md:flex-row justify-between items-center mt-12 mx-auto md:mx-20">
          <div className="mb-4 md:mb-10 ">
            <div className="text-[#65666B] text-base font-semibold leading-normal md:mb-4 mb-2">
              농자재 유통사 및 하우스 시공 업체 대상
            </div>
            <div className="text-[#1F2024] text-[22px] md:text-[32px] font-bold mb-4">
              브이하우스 <span className="text-[#0BB25F]">무료가입</span>하고
              <br />
              <span className="text-[#0BB25F]">온라인으로 바로 이용</span>해 보세요.
            </div>
            <div className="text-[#3A3B40] text-base font-normal leading-relaxed mb-4 md:mb-8 ">
              업체 단위로 가입하고,
              <br className="md:hidden" /> 조직원마다 계정을 부여할 수 있습니다.
            </div>

            <ContainerButton
              onClick={handleSignUpClick}
              className={LandingStyles["common-btn"]}
              size="lg"
              color="primary"
              text="브이하우스 가입하기"
              rightIcon={ChevronRightIcon}
            />
          </div>
          <img
            className="md:max-w-fit w-full max-w-[300px] md:w-2/5 flex md:self-end"
            src="/images/landing/signup.png"
            alt="브이하우스 회원가입"
          />
        </div>
      </div>

      {/* <div className="mx-4 md:mx-0 my-28 text-center ">
        <h1 className="text-2xl md:text-4xl md:leading-12 font-bold text-[#1F2024] mb-4">
          브이하우스에 대해 궁금한 부분이 있나요?
          <br />
          연락 주시면 친절히 알려드리겠습니다
        </h1>
        <div className="text-base md:text-lg font-medium text-[#3A3B40] mb-8">
          운영 시간 : 평일 (월~ 금) 10:00 ~ 18:00
          <TextBody weight={"regular"} size={"sm"} color="gray-80" className="block md:text-md">
            농자재 유통사 및 하우스 시공 업체 대상
          </TextBody>
        </div>

        <ContainerButton
          onClick={handleCall}
          size="xl"
          className={LandingStyles["common-btn"]}
          color="tertiary-color"
          text="전화 문의 02-6953-4379"
          leftIcon={PhoneFillIcon}
        />
      </div> */}
    </>
  );
}

export default withAuth(memo(Landing));
