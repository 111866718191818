type Props = {
  img: string;
  imgAlt: string;
  title: any;
  description: any;
};

function LandingCard({ img, imgAlt, title, description }: Props) {
  return (
    <div className="flex justify-center flex-col items-center mb-8 md:mb-0 last:mb-0">
      <img className="w-[30%]" src={img} alt={imgAlt} />
      <div className="mt-6">
        <h5 className="mb-2 text-xl font-semibold text-[#1F2024] text-center">{title}</h5>
        <div className="whitespace-pre-wrap  text-[15px] text-[#1F2024] text-center">{description}</div>
      </div>
    </div>
  );
}

export default LandingCard;
