import { ContainerButton, ChevronRightLineRegular } from "@greenlabs/formula-components";
import { ReactNode } from "react";

interface Props {
  handleClick: () => void;
  title: ReactNode;
  content: ReactNode;
  buttonText: string;
  className?: string;
}

const ChevronRightIcon = () => {
  return <ChevronRightLineRegular size="lg" color="white" />;
};

const BannerContent = ({ title, content, handleClick, buttonText, className = "" }: Props) => {
  return (
    <div className={`first:mb-8 md:first:mb-0 md:mb-0 ${className} text-center md:py-2 md:pl-2 `}>
      <div className="text-[17px] font-semibold text-[#1F2024] mb-2 md:mb-0">{title}</div>
      <div className="text-sm text-[#3A3B40]">{content}</div>

      <ContainerButton
        onClick={handleClick}
        className="text-sm font-normal mt-4"
        size="sm"
        color="primary"
        text={buttonText}
        rightIcon={ChevronRightIcon}
      />
    </div>
  );
};

export default BannerContent;
