import { ReactNode, RefObject, useEffect, useRef } from "react";
import CountUp from "react-countup";

type Content = {
  title: string;
  value: number;
  valueType: "won" | "count";
};

interface Props {
  className?: string;
  title: ReactNode;
  content: Content[];
}

interface CountContentProps extends Pick<Content, "valueType"> {
  countUpRef: RefObject<HTMLElement>;
  start: () => void;
}

const CountContent = ({ countUpRef, start, valueType }: CountContentProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const intersectionObserverRef = useRef<IntersectionObserver>(null);
  const activeCount = useRef(false);

  useEffect(() => {
    intersectionObserverRef.current = new IntersectionObserver(entries => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting && !activeCount.current) {
          start();
          activeCount.current = true;
        }
      });
    });

    intersectionObserverRef.current.observe(wrapperRef.current);

    return () => {
      intersectionObserverRef.current.disconnect();
    };
  }, [start]);

  return (
    <div ref={wrapperRef} className="text-3xl lg:text-3xl xl:text-4xl font-black ">
      <span ref={countUpRef}></span>
      <span>{valueType === "won" ? " 억원+" : " 건"}</span>
    </div>
  );
};

const CardStat = ({ className = "", title, content }: Props) => {
  const valueFormat = (value: number, valueType: "won" | "count") => {
    if (valueType === "won") return Math.round(value / 100_000_000).toLocaleString();

    return value.toLocaleString();
  };

  return (
    <div
      className={`px-6 lg:px-[3%] py-8 lg:py-11 rounded-2xl flex-col justify-center items-start gap-y-6  lg:gap-10 inline-flex ${className}`}
    >
      <div className="text-white text-xl md:text-3xl font-bold">{title}</div>

      <div className="gap-y-4 lg:items-center  flex text-white  flex-col lg:flex-row w-full">
        {content.map((v, i) => (
          <div className="flex-1" key={`stat-content-${i}`}>
            <div className="text-base font-semibold leading-normal">{v.title}</div>
            <CountUp
              end={Number(v.value)}
              duration={2}
              start={0}
              separator={","}
              formattingFn={value => valueFormat(value, v.valueType)}
            >
              {({ countUpRef, start }) => (
                <CountContent valueType={v.valueType} start={start} countUpRef={countUpRef} />
              )}
            </CountUp>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardStat;
