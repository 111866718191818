import { ReactNode } from "react";

interface Props {
  variant: "single" | "multiple";
  subTitle: ReactNode;
  title: ReactNode;
  content: ReactNode;
  imgUrl: string;
  imgAlt: string;
  className?: string;
}

const variantClass = {
  single: "mb-6 relative pr-[2%] pl-[5%] pt-[5%] w-full  md:flex-row  md:text-left  md:justify-between",
  multiple: "mb-6 md:mb-0 last:mb-0",
};

const DesignCard = ({ variant, title, subTitle, content, imgUrl, imgAlt, className = "" }: Props) => {
  return (
    <div
      className={`pt-8 md:pt-[5%] px-2  rounded-2xl border border-gray-100 flex flex-col items-center text-center justify-end ${variantClass[variant]} ${className}`}
    >
      <div className="mb-4 md:mb-10">
        <h4 className="text-white text-base font-semibold leading-normal md:mb-6 mb-4">{subTitle}</h4>
        <h2 className="font-semibold text-white text-[22px] md:text-3xl  leading-10 mb-4">{title}</h2>
        <h5 className="text-white text-base font-light leading-relaxed  ">{content}</h5>
      </div>
      <img
        className={`w-full flex ${variant === "single" ? "md:self-end md:w-[60%]" : "md:w-[80%]"} `}
        src={imgUrl}
        alt={imgAlt}
      />
    </div>
  );
};

export default DesignCard;
