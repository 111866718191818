export const landingSwiperData = [
  {
    title: "브이하우스 시공 중개",
    content: "팜모닝 90만 농민의\n시공 중개를 받아보세요!",
    button: "브이하우스 가입 하기",
  },
  {
    title: "원스톱 전문 설계",
    content: "단 1분만에 다양한 설비를\n설계, 견적해보세요",
    button: "브이하우스 가입 하기",
  },
];

export const landingCardData = [
  {
    title: "팜모닝을 통한 시설 시공 요청",
    description: "팜모닝에 가입된 90만명 농민 회원들로부터\n하우스 및 스마트팜 시설에 대한\n시공을 요청을 받습니다.",
    imgUrl: "/images/landing/card-request.png",
    imgAlt: "시공 요청",
  },
  {
    title: "브이하우스를 통한 시공 중개",
    description: "농민으로부터 요청 받은 상담 건은 \n브이하우스 가입 업체에게 제공하여\n시공을 중개합니다.",
    imgUrl: "/images/landing/card-brokerage.png",
    imgAlt: "시공 중개",
  },
  {
    title: "상담과 견적 제공하고 시공 거래",
    description: "중개 받은 고객과 상담을 통해\n견적서를 제공하고\n시공 거래까지 진행하세요.",
    imgUrl: "/images/landing/card-trade.png",
    imgAlt: "시공 거래",
  },
];

export const landingDesignGuideData = [
  {
    title: "농민의 견적 요청을\n상담 관리에 기록합니다",
    content: "고객이 하우스 견적을 요청하면,\n상담 관리에 요청 사항을 간략히 기록합니다.",
    imgUrl: "/images/landing/design-guide-1.png",
    imgAlt: "sample",
  },
  {
    title: "설계하기 버튼으로\n요청 사항 기반 견적을 생성합니다",
    content: "고객이 요청한 시설 유형, 폭, 길이 등을 가지고\n버튼 클릭 한 번으로 설계와 견적이 생성됩니다.",
    imgUrl: "/images/landing/design-guide-2.png",
    imgAlt: "sample",
  },
  {
    title: "견적 상세 수정 후\n견적서와 도면 출력합니다",
    content: "설계 기능으로 고객의 상세한 요청을 반영하고,\n견적서와 도면을 출력해서 고객에게 전달하세요.",
    imgUrl: "/images/landing/design-guide-3.png",
    imgAlt: "sample",
  },
];
